// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@partnerbase.cc.

// ----------------------------------------------------------------------

const en = {
	"navbar": {
		"Dashboard": "Dashboard",
		"Notice": "Notice",
		"Notice List": "Notice List",
		"Rebate": "Rebate",
		"Rebate Detail": "Rebate Detail",
		"Rebate Deposit Record": "Rebate Deposit Record",
		"Rebate Record": "Rebate Record",
		"Rebate Details": "Rebate Details",
		"Rebate Transactions": "Rebate Transactions",
		"Customer": "Customer",
		"Customer Report": "Customer Report",
		"Customer Setting": "Customer Setting",
		"Exchange": "Exchange",
		"Deposit/Withdrawal": "Deposit/Withdrawal",
		"Spot Wallet Transactions": "Spot Wallet Transactions",
		"Futures Wallet Transactions": "Futures Wallet Transactions",
		"Terms of Service": "Terms of Service",
		"Referral Uid": "Referral Uid",
		"Referral Link": "Referral Link",
	},

	"dashboard": {
		"Team Daily Report": "Team Daily Report",
		"Yesterday (UTC+0)": "Yesterday (UTC+0)",
		"(Data is updated at UTC+1)": "(Data is updated at UTC+1)",
		"New Registration": "New Registration",
		"Deposit": "Deposit",
		"Transaction Volume": "Transaction Volume",
		"Transaction Fee": "Transaction Fee",
		"Rebate": "Rebate",
		"Deposit Chart": "Deposit Chart",
		"Transaction Volume Chart": "Transaction Volume Chart",
		"Transaction Fee Chart": "Transaction Fee Chart",
		"Rebate Chart": "Rebate Chart",
		"New Registration Chart": "New Registration Chart",
	},
	"rebate": {
		"detail": {
			"Rebate Total Summary": "Rebate Total Summary",
			"Rebate Transactions": "Rebate Transactions",
			"My Rebate": "My Rebate",
			"Self Rebate": "Self Rebate",
			"Client Transaction Fee": "Client Transaction Fee",
			"Client Rebate": "Client Rebate",
			"Partner Transaction Fee": "Partner Transaction Fee",
			"Partner Rebate": "Partner Rebate",
			"User Type": "User Type",
			"Transaction Fee": "Transaction Fee",
			"Rebate Ratio": "Rebate Ratio",
			"Rebate(USDT)": "Rebate(USDT)",
			"Time": "Time",
		},
		"deposit_record": {
			"Coin": "Coin",
			"EXPECTED REBATE": "EXPECTED REBATE",
			"ACTUAL REBATE": "ACTUAL REBATE",
			"Time": "Time"
		}
	},
	"customer": {
		"report": {
			"Rebate Ratio": "Rebate Ratio",
			"User Type": "User Type",
			"Total Rebate": "Total Rebate",
			"Total Transaction Fee": "Total Transaction Fee",
			"Total Deposit": "Total Deposit",
			"Total Withdrawal": "Total Withdrawal",
			"Node Size": "Node Size",
			"Deposit Users": "Deposit Users",
			"Email": "Email",
			"Phone": "Phone",
			"Note": "Note",
			"Registration": "Registration"
		},
		"setting": {
			"Email": "Email",
			"Phone": "Phone",
			"Note": "Note",
			"Rebate Ratio": "Rebate Ratio",
			"Set Partner": "Set Partner",
			"Edit": "Edit",
			"Actions": "Actions",
		}
	},
	"exchange": {
		"Transaction": "Transaction",
		"Deposit": "Deposit",
		"Withdraw": "Withdraw",
		"Coin": "Coin",
		"Quantity": "Quantity",
		"Time": "Time",
		"Spot Wallet Transactions": "Spot Wallet Transactions",
		"Futures Wallet Transactions": "Futures Wallet Transactions",
		"Exchange": "Exchange",
	},
};
export default en;
