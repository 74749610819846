import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import {Box, Button, Container, Grid, IconButton, Link, Typography} from '@mui/material';
// hooks
import {useSnackbar} from "notistack";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {useState} from "react";
import useAuth from '../../../hooks/useAuth';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import MyAvatar from '../../../components/MyAvatar';
import useLocales from "../../../hooks/useLocales";


// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {
  const { user } = useAuth();
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();

  const [showLink, setShowLink] = useState(true);
  const [showVisibilityIcon, setShowVisibilityIcon] = useState(true);
  const [showVisibilityOffIcon, setShowVisibilityOffIcon] = useState(false);

  const handleCopyUIDClick = () => {
    const content = document.getElementById("uid").innerText;
    navigator.clipboard.writeText(content);
    enqueueSnackbar(translate("Copied!"))
  }
  const handleCopyLinkClick = () => {
    const content = document.getElementById("uid").innerText;
    navigator.clipboard.writeText(`https://www.bitmusa.com/signup?ref=${content}`);
    enqueueSnackbar(translate("Copied!"))
  }

  const handleShowHideClick = () => {
    if (showLink) {
      setShowLink(false)
      setShowVisibilityIcon(false)
      setShowVisibilityOffIcon(true)
    } else {
      setShowLink(true)
      setShowVisibilityIcon(true)
      setShowVisibilityOffIcon(false)
    }
  }

  return (
//    <Link underline="none" color="inherit" component={RouterLink} to={PATH_DASHBOARD.user.account}>
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        {/* <MyAvatar /> */}

        <Box
          sx={{
            width: '100%',
            ml: 0,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Typography variant="subtitle2">
            {user?.email.length < 24 ? user?.email : `${user?.email.slice(0, 24)  }...`}
          </Typography>
          <Grid container alignItems="center">
            <Grid item xs={10}>
              <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
                {translate("navbar.Referral Uid")}: <span id="uid">{user?.username}</span>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <IconButton size="small" onClick={handleCopyUIDClick} >
                <ContentCopyIcon fontSize="inherit"/>
              </IconButton>
            </Grid>
          </Grid>
          <Grid container justify="flex-start"
                alignItems="center">
            <Grid item xs={10}>
              <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
                {translate("navbar.Referral Link")}
                {/* : <span id="uid">{user?.username}</span> */}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <IconButton size={"small"} onClick={handleCopyLinkClick} >
                <ContentCopyIcon fontSize="inherit"/>
              </IconButton>
            </Grid>
          </Grid>
          {user?.rb_child === 0 ? "" :
            <Grid container alignItems="center">
              <Grid item xs={10}>
                {showLink &&
                  <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
                    {translate("Rebate Ratio")}: {user?.rb_child === null ? '100' : user?.rb_child*100}%

                  </Typography>
                }
              </Grid>
              <Grid item xs={2}>

                {showVisibilityIcon &&
                  <IconButton size={"small"} onClick={handleShowHideClick}>
                    <VisibilityIcon fontSize="inherit"/>
                  </IconButton>}
                {showVisibilityOffIcon &&
                  <IconButton size={"small"} onClick={handleShowHideClick} >
                    <VisibilityOffIcon fontSize="inherit"/>
                  </IconButton> }
              </Grid>
            </Grid>
          }
        </Box>
      </RootStyle>
//    </Link>
  );
}
