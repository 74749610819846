// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@marketboro.com.

// ----------------------------------------------------------------------

const ja = {

  "Sign in to Partnerbase": "Partnerbase にサインインする",
  "Enter UID": "UID を入力してください",
  "Enter Password": "パスワードを入力する",
  "Save my UID": "UID を保存する。",
  "Reset password?": "パスワードを探す。",
  "Login": "ログイン",
  "Logout" : "ログアウト",
  "Back": "戻る",
  "Password": "パスワード",
  "New Notice": "新しい通知",
  "Don't Show": "表示しない",
  "Close": "近い",
  "Confirm": "確認",
  "Cancel": "キャンセル",
  "Please check the rebate rate.": "還元率をご確認ください。",
  "When changing your partner's rebate, it must be equal to or lower than your rebate.": "パートナーのリベートを変更するときは、私のリベートと同じか低くする必要があります。",
  // ============ Strapi Error Message.
  "Invalid identifier or password": "ログイン情報が一致しません。もう一度お試しください。",
  "Email is already taken": "メールは既に使用されています",
  "Only Partner Available.": "唯一のパートナーが利用可能",
  "Please reset password.": "パスワードをリセットしてください。",
  // Strapi Error Message. =====================

  "UID is required": "UID が必要です",
  "Password is required": "パスワードが必要です",
  "Email is required": "メールアドレス必須",
  "Email must be a valid email address": "メールアドレスは有効なメールアドレスでなければなりません",
  "Email address": "メールアドレス",
  "Reset Password": "パスワード変更",
  "Please enter the email address associated with your UID and We will email you a code to reset your password.": "UID に関連付けられている電子メール アドレスを入力してください。パスワードをリセットするためのコードが電子メールで送信されます。",
  "Request sent successfully!": "リクエストが正常に送信されました。",
  "We've sent a 6-digit confirmation email to your email.": "お客様のメールアドレスに 6 桁の確認メールを送信しました。",
  "Please enter the code in below box to verify your email.": "メールを確認するには、下のボックスにコードを入力してください。",
  "Confirm New Password": "パスワード確認",
  "Code is required": "コードが必要です",
  "Password must be at least 6 characters": "パスワードは最低でも6文字必要です",
  "Confirm password is required": "パスワードの確認が必要です",
  "Passwords must match": "パスワードが一致しません。",
  "Change password": "パスワードの変更",
  "UID and email are different.": "UID と電子メールは異なります。",
  "UID does not exist.": "UID が存在しません。",
  "Change password success!": "パスワード変更成功！",
  "Search" : "検索",
  "Start date" : "開始日",
  "End date" : "締め切り",
  "all": "전체",
  "Partner" : "相棒",
  "Client" : "クライアント",
  "User Type" : "ユーザータイプ",
  "Email" : "メールアドレス",
  "Phone" : "電話番号",
  "Note" : "備考",
  "Phone (Option)" : "電話番号 (オプション)",
  "Note (Option)" : "備考 (オプション)",
  "The rebate will be transferred by 07:00 (UTC) on the next day.": "リベートは翌日UTC基準7時まで入金されます。",

  "navbar" : {
    "Dashboard" : "ダッシュボード",
    "Notice": "お知らせ",
    "Notice List": "お知らせ一覧",
    "Rebate" : "リベート記録",
    "Rebate Detail" : "詳細履歴",
    "Rebate Deposit Record" : "入金記録",
    "Rebate Record": "リベート記録",
    "Rebate Details": "詳細履歴",
    "Rebate Transactions": "入金記録",
    "Customer" : "ユーザー管理",
    "Customer Report" : "アカウントの現状",
    "Customer Setting" : "アカウント設定",
    "Exchange" : "入金/出金",
    "Deposit/Withdrawal" : "入金/出金",
    "Spot Wallet Transactions" : "現物ウォレット入金/出金",
    "Futures Wallet Transactions" : "先物ウォレット入金/出金",
    "Terms of Service" : "利用規約",
    "Referral Uid" : "推奨コード",
    "Referral Link" : "レフェラルリンク",
    "Rebate Ratio" : "リベート率 : "
  },


  // 대시보드
  "Rebate Ratio": "リベート率",
  "Rebate Ratio(%)": "リベート率(%)",
  "Dashboard" : "ダッシュボード",
  "Notice" : "お知らせ",
  "Rebate": "リベート記録",
  "User": "ユーザー",
  "Wallet": "財布",
  "User Management": "ユーザー管理",
  "Accounts Overview": "アカウントの現状",
  "Configurations": "アカウント設定",
  "Deposit/Withdrawal": "入金/出金",
  "exchange_spot" : "現物ウォレット入金/出金",
  "exchange_futures" : "先物ウォレット入金/出金",

  "dashboard" : {
    "Team Daily Report": "昨日チームの入金額",
    "Yesterday (UTC+0)" : "昨日 (UTC+0)",
    "(Data is updated at UTC+1)": "(データは UTC+1 に更新されます)",
    "New Registration" : "昨日チームの新規加入者",
    "Deposit" : "昨日チームの入金額",
    "Transaction Volume" : "昨日チームの取引額",
    "Transaction Fee" : "昨日チームの手数料",
    "Rebate" : "昨日チームのリベート",
    "Deposit Chart" : "チームのデイリー入金額",
    "Transaction Volume Chart" : "チームの 1 日取引量",
    "Transaction Fee Chart" : "チームの毎日の取引手数料",
    "Rebate Chart" : "チームのデイリーリベート",
    "New Registration Chart" : "チームのデイリー新規登録者",
  },

  // 공지사항
  "Notice List": "お知らせ一覧",
  "List": "リスト",
  "No": "番号",
  "Title": "題名",
  "Date": "日にち",
  "Dense": "狭く見る",
  "Notice Details": "お知らせを見る",
  "Go List": "リストとして",

  "rebate" : {
    "detail" : {
      "Rebate Total Summary" : "リベート累積状況",
      "Rebate Transactions": "入金記録",
      "My Rebate" : "リベート累積合計",
      "Self Rebate" : "マイセルプリベート累積合計",
      "Client Transaction Fee" : "顧客手数料累積合計",
      "Client Rebate" : "顧客リベート累積合計",
      "Partner Transaction Fee" : "パートナー手数料累積合計",
      "Partner Rebate" : "パートナーリベート累積合計",
      "User Type" : "ユーザータイプ",
      "Transaction Fee": "取引手数料",
      "Rebate Ratio": "リベート率",
      "Rebate(USDT)": "リベート率(USDT)",
      "Time": "発生日時",
    },
    "deposit_record" : {
      "Coin" : "コイン",
      "EXPECTED REBATE" : "予想リベート",
      "ACTUAL REBATE" : "確定リベート",
      "Time" : "振替日時"
    }
  },
  "customer" : {
    "report" : {
      "Rebate Ratio" : "リベート率 (%)",
      "User Type" : "ユーザータイプ",
      "Total Rebate" : "リベート合計",
      "Total Transaction Fee" : "手数料合計",
      "Total Deposit" : "入金合計",
      "Total Withdrawal" : "出金合計",
      "Node Size" : "チームサイズ",
      "Deposit Users" : "入金ユーザー数",
      "Email" : "メールアドレス",
      "Phone" : "電話番号",
      "Note" : "備考",
      "Registration" : "新規登録日時"
    },
    "setting" : {
      "Email" : "メールアドレス",
      "Phone" : "電話番号",
      "Note" : "備考",
      "Rebate Ratio" : "リベート率 (%)",
      "Set Partner" : "パートナー設定",
      "Edit" : "修正",
      "Actions": "ツール",
    }
  },
  "exchange" : {
    "Transaction" : "取引タイプ",
    "Deposit" : "订金",
    "Withdraw" : "提取",
    "Coin" : "コイン",
    "Quantity" : "数量",
    "Time" : "入出金日時",
    "Spot Wallet Transactions" : "現物ウォレット入金/出金",
    "Futures Wallet Transactions" : "先物ウォレット入金/出金",
    "Exchange" : "入金/出金",
  },
};

export default ja;
