import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        {
          path: 'notice',
          children: [
            { element: <Navigate to="/dashboard/notice/list" replace />, index: true },
            { path: 'list', element: <NoticeList /> },
            { path: ':id', element: <NoticeDetails /> },
          ],
        },
        {
          path: 'rebate',
          children: [
            { element: <Navigate to="/dashboard/rebate/list" replace />, index: true },
            { path: 'list', element: <RebateList /> },
            { path: 'record', element: <RebateRecord /> },
          ],
        },
        {
          path: 'accounts',
          children: [
            { element: <Navigate to="/dashboard/accounts/list" replace />, index: true },
            { path: 'list', element: <AccountsList /> },
            { path: 'settings', element: <AccountsSettings /> },
          ],
        },
        {
          path: 'wallet',
          children: [
            { element: <Navigate to="/dashboard/wallet/spot" replace />, index: true },
            { path: 'spot', element: <SpotList /> },
            { path: 'futures', element: <FuturesList /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    // zauin 홈페이지를 dashboard/app 으로
    { path: '/', element: <Navigate to="/dashboard" /> },
    // {
    //   path: '/',
    //   element: <MainLayout />,
    //   children: [
    //     { element: <HomePage />, index: true },
    //   ],
    // },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));

// Notice
const NoticeList = Loadable(lazy(() => import('../pages/dashboard/NoticeList')));
const NoticeDetails = Loadable(lazy(() => import('../pages/dashboard/NoticeDetails')));

// Rebate
const RebateList = Loadable(lazy(() => import('../pages/dashboard/RebateList')));
const RebateRecord = Loadable(lazy(() => import('../pages/dashboard/RebateRecord')));

// Accounts Overview
const AccountsList = Loadable(lazy(() => import('../pages/dashboard/AccountsList')));
const AccountsSettings = Loadable(lazy(() => import('../pages/dashboard/AccountsSettings')));

// Wallet
const SpotList = Loadable(lazy(() => import('../pages/dashboard/SpotList')));
const FuturesList = Loadable(lazy(() => import('../pages/dashboard/FuturesList')));

const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
