// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'navbar.Dashboard',
    items: [
      { title: 'navbar.Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
    ],
  },

  // Notice
  // ----------------------------------------------------------------------
  {
    subheader: 'navbar.Notice',
    items: [
      // Notice
      {
        title: 'navbar.Notice',
        path: PATH_DASHBOARD.notice.root,
        icon: ICONS.invoice,
        children: [
          { title: 'navbar.Notice List', path: PATH_DASHBOARD.notice.list },
        ],
      },
    ],
  },

  // Rebate Record
  // ----------------------------------------------------------------------
  {
    subheader: 'navbar.Rebate',
    items: [
      // Rebate
      {
        title: 'navbar.Rebate Record',
        path: PATH_DASHBOARD.rebate.root,
        icon: ICONS.invoice,
        children: [
          { title: 'navbar.Rebate Details', path: PATH_DASHBOARD.rebate.list },
          { title: 'navbar.Rebate Transactions', path: PATH_DASHBOARD.rebate.record },
        ],
      },

    ],
  },

  // User
  // ----------------------------------------------------------------------
  {
    subheader: 'User',
    items: [
      // USER
      {
        title: 'User Management',
        path: PATH_DASHBOARD.accounts.root,
        icon: ICONS.user,
        children: [
          { title: 'Accounts Overview', path: PATH_DASHBOARD.accounts.list },
          { title: 'Configurations', path: PATH_DASHBOARD.accounts.settings },
        ],
      },

    ],
  },

  // User
  // ----------------------------------------------------------------------
  {
    subheader: 'navbar.Exchange',
    items: [
      // USER
      {
        title: 'navbar.Deposit/Withdrawal',
        path: PATH_DASHBOARD.wallet.root,
        icon: ICONS.banking,
        children: [
          { title: 'navbar.Spot Wallet Transactions', path: PATH_DASHBOARD.wallet.spot },
          { title: 'navbar.Futures Wallet Transactions', path: PATH_DASHBOARD.wallet.futures },
        ],
      },

    ],
  },

];

export default navConfig;
