// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@partnerbase.cc.

// ----------------------------------------------------------------------

const vn = {

  "Sign in to Partnerbase": "Đăng nhập vào Partnerbase",
  "Enter UID": "Nhập UID",
  "Enter Password": "Nhập mật khẩu",
  "Save my UID": "Bảo lưu UID",
  "Reset password?": "Quên mật khẩu",
  "Login": "Đăng nhập",
  "Logout" : "Đăng xuất",
  "Back": "Mặt sau",
  "Password": "Mật khẩu",
  "New Notice": "Thông báo mới",
  "Don't Show": "không hiển thị",
  "Close": "Đóng",
  "Confirm": "Xác nhận",
  "Cancel": "Hủy bỏ",
  "Please check the rebate rate.": "Vui lòng kiểm tra tỷ lệ hoàn tiền.",
  "When changing your partner's rebate, it must be equal to or lower than your rebate.": "Khi thay đổi khoản chiết khấu của đối tác, khoản chiết khấu đó phải bằng hoặc thấp hơn khoản chiết khấu của bạn.",
  // ============ Strapi Error Message.
  "Invalid identifier or password": "Đăng nhập thất bại. Vui lòng thử lại.",
  "Email is already taken": "Email này đã được sử",
  "Only Partner Available.": "Chỉ đối tác có sẵn",
  "Please reset password.": "Vui lòng đặt lại mật khẩu.",
  // Strapi Error Message. =====================

  "UID is required": "UID là bắt buộc",
  "Password is required": "mật khẩu là bắt buộc",
  "Email is required": "Email thì cần thiết",
  "Email must be a valid email address": "Email phải là một địa chỉ email hợp lệ",
  "Email address": "Địa chỉ email",
  "Reset Password": "Quên mật khẩu",
  "Please enter the email address associated with your UID and We will email you a code to reset your password.": "Vui lòng nhập địa chỉ email được liên kết với UID của bạn và Chúng tôi sẽ gửi email cho bạn một mã để đặt lại mật khẩu của bạn.",
  "Request sent successfully!": "Yêu cầu được gửi thành công!",
  "We've sent a 6-digit confirmation email to your email.": "Chúng tôi đã gửi email xác nhận gồm 6 chữ số đến email của bạn.",
  "Please enter the code in below box to verify your email.": "Hãy cho vào mã xác minh được gửi đến email của bạn",
  "Confirm New Password": "Xác nhận mật khẩu",
  "Code is required": "mã là bắt buộc",
  "Password must be at least 6 characters": "mật khẩu phải ít nhất 6 kí tự",
  "Confirm password is required": "Xác nhận mật khẩu là bắt buộc",
  "Passwords must match": "Mất khẩu không hợp lệ.",
  "Change password": "Đổi mật khẩu",
  "UID and email are different.": "UID và email khác nhau.",
  "UID does not exist.": "UID không tồn tại.",
  "Change password success!": "Đổi mật khẩu thành công!",
  "Search" : "Tìm kiếm",
  "Start date" : "ngày bắt đầu",
  "End date" : "Ngày kết thúc",
  "all": "전체",
  "Partner" : "Bạn đồng hành",
  "Client" : "Khách hàng",
  "User Type" : "Loại hình người dùng",
  "Email" : "e-mail",
  "Phone" : "Số điện thoại",
  "Note" : "Ghi chú",
  "Phone (Option)" : "Số điện thoại (Quyền mua)",
  "Note (Option)" : "Ghi chú (Quyền mua)",
  "The rebate will be transferred by 07:00 (UTC) on the next day.": "Khoản tiền hoàn lại sẽ được gửi trước 7:00 UTC ngày hôm sau.",

  "navbar" : {
    "Dashboard" : "bảng điều khiển",
    "Notice": "Để ý",
    "Notice List": "Danh sách",
    "Rebate" : "Hồ sơ giảm giá",
    "Rebate Detail" : "thông tin chi tiết",
    "Rebate Deposit Record" : "hồ sơ tiền gửi",
    "Rebate Record": "hồ sơ tiền gửi",
    "Rebate Details": "thông tin chi tiết",
    "Rebate Transactions": "hồ sơ tiền gửi",
    "Customer" : "quản lý khách hàng",
    "Customer Report" : "Tình trạng tài khoản khách hàng",
    "Customer Setting" : "Cài đặt tài khoản khách hàng",
    "Exchange" : "Gửi / Rút tiền",
    "Deposit/Withdrawal" : "Gửi / Rút tiền",
    "Spot Wallet Transactions" : "Gửi / Rút tiền Ví giao ngay",
    "Futures Wallet Transactions" : "Nạp/ rút tiền ví trả sau",
    "Terms of Service" : "Điều khoản dịch vụ",
    "Referral Uid" : "Mã giới thiệu",
    "Referral Link" : "liên kết giới thiệu",
    "Rebate Ratio" : "Tỷ lệ giảm giá : "
  },


  // 대시보드
  "Rebate Ratio": "Tỷ lệ giảm giá",
  "Rebate Ratio(%)": "tỷ lệ giảm giá (%)",
  "Dashboard" : "bảng điều khiển",
  "Notice" : "Để ý",
  "Rebate": "Hồ sơ giảm giá",
  "User": "사용자",
  "Wallet": "지갑",
  "User Management": "quản lý khách hàng",
  "Accounts Overview": "Tình trạng tài khoản khách hàng",
  "Configurations": "Cài đặt tài khoản khách hàng",
  "exchange_spot" : "Gửi / Rút tiền Ví giao ngay",
  "exchange_futures" : "Nạp/ rút tiền ví trả sau",
  "Exchange" : "Gửi / Rút tiền",
  "Deposit/Withdrawal" : "Gửi / Rút tiền",

  "dashboard" : {
    "Team Daily Report": "Team Daily Report",
    "Yesterday (UTC+0)" : "Thống kê hôm trước (UTC + 0)",
    "(Data is updated at UTC+1)": "(Dữ liệu được cập nhật trên UTC + 1)",
    "New Registration" : "Thành viên mới gia nhập nhóm hôm trước",
    "Deposit" : "Tiền gửi của nhóm ngày hôm qua",
    "Transaction Volume" : "Giao dịch của nhóm hôm trước",
    "Transaction Fee" : "Phí giao dịch của nhóm hôm trước",
    "Rebate" : "Thông tin giảm giá của nhóm hôm qua",
    "Deposit Chart" : "Bảng xếp hạng xu hướng nạp tiền",
    "Transaction Volume Chart" : "Bảng xếp hạng xu hướng giao dịch",
    "Transaction Fee Chart" : "Bảng xếp hạng xu hướng phí giao dịch",
    "Rebate Chart" : "Hoàn trả hàng ngày cho đội",
    "New Registration Chart" : "Bảng xếp hạng xu hướng gia nhập thành viên mới",
  },

  // 공지사항
  "Notice List": "Để ý Danh sách",
  "List": "Danh sách",
  "No": "con số",
  "Title": "Tiêu đề",
  "Date": "Ngày",
  "Dense": "tầm nhìn hẹp",
  "Notice Details": "Xem thông báo",
  "Go List": "liệt kê",

  "rebate" : {
    "detail" : {
      "Rebate Total Summary" : "Trạng thái tích lũy giảm giá",
      "Rebate Transactions": "hồ sơ tiền gửi",
      "My Rebate" : "Tống giảm giá của tôi",
      "Self Rebate" : "Tổng cộng dồn tự giảm giá",
      "Client Transaction Fee" : "Tổng phí giao dịch tích luỹ của khách hàng",
      "Client Rebate" : "Tổng giảm giá tích luỹ của khách hàng",
      "Partner Transaction Fee" : "Tổng phí giao dịch tích luỹ của đối tác",
      "Partner Rebate" : "Tổng chiết khấu tích luỹ của đối tác",
      "User Type" : "Loại hình người dùng",
      "Transaction Fee": "Phí giao dịch",
      "Rebate Ratio": "Tỷ lệ giảm",
      "Rebate(USDT)": "Giảm giá(USDT)",
      "Time": "Ngày phát sinh",
    },
    "deposit_record" : {
      "Coin" : "Coin",
      "EXPECTED REBATE" : "Giảm giá dự kiến",
      "ACTUAL REBATE" : "Giảm giá đã xác nhận",
      "Time" : "ngày chuyển giao"
    }
  },
  "customer" : {
    "report" : {
      "Rebate Ratio" : "tỷ lệ giảm giá (%)",
      "User Type" : "Loại hình người dùng",
      "Total Rebate" : "tổng giảm giá",
      "Total Transaction Fee" : "Tổng các khoản phí",
      "Total Deposit" : "Tổng số tiền gửi",
      "Total Withdrawal" : "Tổng số tiền rút",
      "Node Size" : "kích thước nhóm",
      "Deposit Users" : "Số lượng người dùng gửi tiền",
      "Email" : "e-mail",
      "Phone" : "Số điện thoại",
      "Note" : "Ghi chú",
      "Registration" : "Ngày đăng ký"
    },
    "setting" : {
      "thEmail" : "e-mail",
      "thTel" : "Số điện thoại",
      "thNote" : "Ghi chú",
      "Rebate Ratio" : "tỷ lệ giảm giá (%)",
      "Set Partner" : "Cài đặt đối tác",
      "Edit" : "điều chỉnh",
      "Actions": "dụng cụ",
    }
  },
  "exchange" : {
    "Transaction" : "gửi và rút tiền",
    "Deposit" : "đặt cọc",
    "Withdraw" : "rút",
    "Coin" : "Coin",
    "Quantity" : "Số lượng",
    "Time" : "Ngày gửi và rút tiền",
    "Exchange" : "Gửi / Rút tiền",
    "Deposit/Withdrawal" : "Gửi / Rút tiền",
    "Spot Wallet Transactions" : "Gửi / Rút tiền Ví giao ngay",
    "Futures Wallet Transactions" : "Nạp/ rút tiền ví trả sau",
  },
};

export default vn;
