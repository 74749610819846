// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
//  register: path(ROOTS_AUTH, '/register'),
//  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
//  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {

  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
  },

  notice: {
    root: path(ROOTS_DASHBOARD, '/notice'),
    list: path(ROOTS_DASHBOARD, '/notice/list'),
    view: (id) => path(ROOTS_DASHBOARD, `/notice/${id}`),
  },

  rebate: {
    root: path(ROOTS_DASHBOARD, '/rebate'),
    list: path(ROOTS_DASHBOARD, '/rebate/list'),
    record: path(ROOTS_DASHBOARD, '/rebate/record'),
  },

  // User Management - Accounts Overview
  accounts: {
    root: path(ROOTS_DASHBOARD, '/accounts'),
    list: path(ROOTS_DASHBOARD, '/accounts/list'),
    settings: path(ROOTS_DASHBOARD, '/accounts/settings'),
  },

  // Deposit/Withdrawal
  wallet: {
    root: path(ROOTS_DASHBOARD, '/wallet'),
    spot: path(ROOTS_DASHBOARD, '/wallet/spot'),
    futures: path(ROOTS_DASHBOARD, '/wallet/futures'),
  },


};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
