// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@partnerbase.cc.

// ----------------------------------------------------------------------

const cn = {

  "Sign in to Partnerbase": "登录到 Partnerbase",
  "Enter UID": "输入 UID",
  "Enter Password": "输入密码",
  "Save my UID": "保存UID",
  "Reset password?": "查找密码",
  "Login": "登录",
  "Logout" : "退出",
  "Back": "后面的",
  "Password": "密码",
  "New Notice": "新通知",
  "Don't Show": "不要显示",
  "Close": "关",
  "Confirm": "确认",
  "Cancel": "取消",
  "Please check the rebate rate.": "请检查回扣率。",
  "When changing your partner's rebate, it must be equal to or lower than your rebate.": "更改合作伙伴的返利时，它必须等于或低于您的返利。",
  // ============ Strapi Error Message.
  "Invalid identifier or password": "您输入的信息不正确。请再试一次。",
  "Email is already taken": "电子邮件已经被采取",
  "Only Partner Available.": "只有合作伙伴可用",
  "Please reset password.": "请重设密码。",
  // Strapi Error Message. =====================

  "UID is required": "需要 UID",
  "Password is required": "密码是必需的",
  "Email is required": "电子邮件是必需的",
  "Email must be a valid email address": "电子邮件必须是有效的电子邮件地址",
  "Email address": "电子邮件地址",
  "Reset Password": "查找密码",
  "Please enter the email address associated with your UID and We will email you a code to reset your password.": "请输入与您的 UID 关联的电子邮件地址，我们将通过电子邮件向您发送代码以重置您的密码。",
  "Request sent successfully!": "请求发送成功！",
  "We've sent a 6-digit confirmation email to your email.": "我们已向您的邮箱发送了一封 6 位数的确认邮件。",
  "Please enter the code in below box to verify your email.": "请在下面的框中输入代码以验证您的电子邮件。",
  "Confirm New Password": "确认密码",
  "Code is required": "代码是必需的",
  "Password must be at least 6 characters": "密码必须至少6个字符",
  "Confirm password is required": "确认密码是必需的",
  "Passwords must match": "您输入的密码不正确。",
  "Change password": "更改密码",
  "UID and email are different.": "UID 和电子邮件是不同的。",
  "UID does not exist.": "UID 不存在。",
  "Change password success!": "修改密码成功！",
  "Search" : "搜索",
  "Start date" : "开始日期",
  "End date" : "最后期限",
  "all": "전체",
  "Partner" : "伙伴",
  "Client" : "客户",
  "User Type" : "用户类型",
  "Email" : "邮件",
  "Phone" : "手机号码",
  "Note" : "备注",
  "Phone (Option)" : "手机号码 (选项)",
  "Note (Option)" : "备注 (选项)",
  "The rebate will be transferred by 07:00 (UTC) on the next day.": "回扣将在第二天 7:00 UTC 之前存入。",

  "navbar" : {
    "Dashboard" : "总览",
    "Notice": "通知",
    "Notice List": "通知 목록",
    "Rebate" : "返利记录",
    "Rebate Detail" : "细节",
    "Rebate Deposit Record" : "返利记录",
    "Rebate Record": "返利记录",
    "Rebate Details": "细节",
    "Rebate Transactions": "存款记录",
    "Customer" : "用户管理",
    "Customer Report" : "客户账户状态",
    "Customer Setting" : "客户账户设置",
    "Exchange" : "存款/取款",
    "Deposit/Withdrawal" : "存款/取款",
    "Spot Wallet Transactions" : "现货钱包充提",
    "Futures Wallet Transactions" : "礼品钱包存款/取款",
    "Terms of Service" : "服务条款",
    "Referral Uid" : "推荐代码",
    "Referral Link" : "推荐链接",
    "Rebate Ratio" : "回扣率 : "
  },


  // 대시보드
  "Rebate Ratio": "回扣率",
  "Rebate Ratio(%)": "回扣率 (%)",
  "Dashboard" : "总览",
  "Notice" : "通知",
  "Rebate": "返利记录",
  "User": "사용자",
  "Wallet": "지갑",
  "User Management": "用户管理",
  "Accounts Overview": "客户账户状态",
  "Configurations": "客户账户设置",

  "Deposit/Withdrawal": "存款/取款",
  "exchange_spot" : "现货钱包充提",
  "exchange_futures" : "礼品钱包存款/取款",

  "dashboard" : {
    "Team Daily Report": "团队日报",
    "Yesterday (UTC+0)" : "昨天 (UTC+0)",
    "(Data is updated at UTC+1)": "(数据在 UTC+1 更新)",
    "New Registration" : "团队昨天新成员",
    "Deposit" : "团队昨天的存款",
    "Transaction Volume" : "团队昨天的交易",
    "Transaction Fee" : "球队昨天的费用",
    "Rebate" : "团队昨天返利",
    "Deposit Chart" : "团队每日存款",
    "Transaction Volume Chart" : "团队每日交易量",
    "Transaction Fee Chart" : "团队每日交易费",
    "Rebate Chart" : "团队每日返利",
    "New Registration Chart" : "团队每日新注册",
  },

  // 공지사항
  "Notice List": "通知 列表",
  "List": "列表",
  "No": "数字",
  "Title": "标题",
  "Date": "日期",
  "Dense": "狭隘的观点",
  "Notice Details": "通知 例子",
  "Go List": "去列表",

  "rebate" : {
    "detail" : {
      "Rebate Total Summary" : "返利累积状态",
      "Rebate Transactions": "存款记录",
      "My Rebate" : "返利累计总额",
      "Self Rebate" : "自回扣累计总额",
      "Client Transaction Fee" : "累计客户费用",
      "Client Rebate" : "累计客户返利",
      "Partner Transaction Fee" : "合作伙伴费用累计",
      "Partner Rebate" : "合作伙伴返利累计总额",
      "User Type" : "用户类型",
      "Transaction Fee": "交易手续费",
      "Rebate Ratio": "回扣率",
      "Rebate(USDT)": "回扣(USDT)",
      "Time": "发生日期",
    },
    "deposit_record" : {
      "Coin" : "货币",
      "EXPECTED REBATE" : "预期回扣",
      "ACTUAL REBATE" : "确认回扣",
      "Time" : "转账日期"
    }
  },
  "customer" : {
    "report" : {
      "Rebate Ratio" : "回扣率 (%)",
      "User Type" : "用户类型",
      "Total Rebate" : "总回扣",
      "Total Transaction Fee" : "总交易手续费",
      "Total Deposit" : "总存款",
      "Total Withdrawal" : "总提款",
      "Node Size" : "团队规模",
      "Deposit Users" : "充值用户数",
      "Email" : "邮件",
      "Phone" : "手机号码",
      "Note" : "备注",
      "Registration" : "注册日期"
    },
    "setting" : {
      "Deposit Users" : "充值用户数",
      "Email" : "邮件",
      "Phone" : "手机号码",
      "Note" : "备注",
      "Rebate Ratio" : "回扣率 (%)",
      "Set Partner" : "合作伙伴设置",
      "Edit" : "修正",
      "Actions": "工具",
    }
  },
  "exchange" : {
    "Transaction" : "交易类型",
    "Deposit" : "入金",
    "Withdraw" : "出金",
    "Coin" : "货币",
    "Quantity" : "数量",
    "Time" : "存取款日期",
    "Spot Wallet Transactions" : "现货钱包充提",
    "Futures Wallet Transactions" : "礼品钱包存款/取款",
    "Exchange" : "存款/取款",
  },
};

export default cn;
