import { accessToken } from 'mapbox-gl';
import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
import useLocales from "../hooks/useLocales";

// ----------------------------------------------------------------------
const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { translate } = useLocales();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          // const response = await axios.get('/api/account/my-account');
          // Strapi 용으로 수정
          const response = await axios.get('/api/users/me');
          const user  = response.data;
//          console.log(response.data)
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {

    // 파트너 여부 체크 
    const resPaternerYN = await axios.get(`/api/auth/verify-partner-code?uid=${email}`);
    const meta = resPaternerYN.data.data.meta;

    // confirmed가 false 이면, 카프카를 통해 가입된 회원이라 비번을 설정해야 이용 가능
    if(meta.partner_yn === 'N') {
      console.log('파트너만 로그인 가능합니다.')
      alert(translate('Only Partner Available.'));
      sessionStorage.removeItem('uid-recovery');
      sessionStorage.removeItem('email-recovery');
      localStorage.removeItem('uid');
      return false;
    }

    // confirmed가 false 이면, 카프카를 통해 가입된 회원이라 비번을 설정해야 이용 가능
    if(!meta.confirmed) {
      console.log('비번 초기화해야 로그인 가능')
      alert('Please reset password.'); 
      return false;
    }

    // meta.old_yn == 'Y' 이면 비밀번호를 예전 것에서 가져와 아래 jwt인증 받기 위해 진행
    if(meta.old_yn === 'Y') {
      const legacyLoginResponse = await axios.post('/api/auth/login-request', {
        "uid": email,
        "password": password
      });
      console.log(legacyLoginResponse.status);
    }

    const response = await axios.post('/api/auth/local', {
      "identifier": email,
      "password": password
    });
    const accessToken = response.data.jwt
    const user = response.data.user;

    if(meta.top_level === 'Y') {
      user.top_level = "Y"
    }
    user.parent_user = meta.parent_user

    setSession(accessToken);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
