// ______________________________________________________________________

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@partnerbase.cc.

// ______________________________________________________________________

const kr = {

	"Sign in to Partnerbase": "로그인",
	"Enter UID": "UID를 입력하세요.",
	"Enter Password": "비밀번호를 입력하세요.",
	"Save my UID": "자동로그인",
	"Reset password?": "비밀번호찾기",
	"Login": "로그인",
	"Logout" : "로그아웃",
	"Back": "뒤로",
	"Password": "비밀번호",
	"New Notice": "새 공지사항",
	"Don't Show": "다시 보지 않기",
	"Close": "닫기",
	"Confirm": "확인",
	"Cancel": "취소",
	"Please check the rebate rate.": "리베이트 비율을 확인해주세요.",
	"When changing your partner's rebate, it must be equal to or lower than your rebate.": "파트너의 리베이트 변경할 때, 나의 리베이트와 같거나 낮아야 합니다.",
	// ============ Strapi Error Message.
	"Invalid identifier or password": "로그인정보가 일치하지 않습니다. 다시 시도해 주세요.",
	"Email is already taken": "이미 사용중인 이메일입니다.",
	"Only Partner Available.": "파트너만 로그인 할 수 있습니다.",
	"Please reset password.": "비밀번호를 초기화해주세요.",
	// Strapi Error Message. =====================

	"UID is required": "UID는 필수입니다.",
	"Password is required": "비밀번호는 필수입니다.",
	"Email is required": "이메일은 필수입니다.",
	"Email must be a valid email address": "이메일을 정확히 입력해주세요.",
	"Email address": "이메일 주소",
	"Reset Password": "비밀번호 재설정",
	"Please enter the email address associated with your UID and We will email you a code to reset your password.": "UID와 연결된 이메일 주소를 입력하면 비밀번호를 재설정할 수 있는 코드를 이메일로 보내드립니다.",
	"Request sent successfully!": "이메일로 인증번호를 발송했습니다!",
	"We've sent a 6-digit confirmation email to your email.": "귀하의 이메일로 6자리 코드를 이메일로 보냈습니다.",
	"Please enter the code in below box to verify your email.": "이메일 확인 후 코드를 입력하세요.",
	"Confirm New Password": "비밀번호 확인",
	"Code is required": "확인 코드를 입력하세요.",
	"Password must be at least 6 characters": "비밀번호는 6자 이상이어야 합니다.",
	"Confirm password is required": "비밀번호 확인란을 입력하세요.",
	"Passwords must match": "비밀번호가 일치해야합니다",
	"Change password": "비밀번호 변경",
	"UID and email are different.": "UID와 이메일이 일치하지 않습니다.",
	"UID does not exist.": "UID가 존재하지 않습니다.",
	"Change password success!": "비밀번호를 변경했습니다.",
	"Search" : "검색",
	"Start date" : "시작일",
	"End date" : "마감일",
	"all": "전체",
	"Partner" : "파트너",
	"Client" : "고객",
	"User Type" : "사용자유형",
	"Email" : "이메일",
	"Phone" : "전화번호",
	"Note" : "비고",
	"Phone (Option)" : "전화번호 (선택)",
	"Note (Option)" : "비고 (선택)",
	"The rebate will be transferred by 07:00 (UTC) on the next day.": "리베이트는 익일 UTC 7시까지 입금됩니다.",

	"navbar" : {
		"Dashboard" : "대쉬보드",
		"Notice": "공지사항",
		"Notice List": "목록",
		"Rebate" : "리베이트",
		"Rebate Detail" : "상세내역",
		"Rebate Deposit Record" : "입금기록",
		"Rebate Record": "리베이트 내역",
		"Rebate Details": "상세내역",
		"Rebate Transactions": "입금기록",
		"Customer" : "고객관리",
		"Customer Report" : "고객계정현황",
		"Customer Setting" : "고객계정설정",
		"Exchange" : "입출금내역",
		"Deposit/Withdrawal" : "입출금내역",
		"Spot Wallet Transactions" : "현물지갑 입금/출금",
		"Futures Wallet Transactions" : "선물지갑 입금/출금",
		"Terms of Service" : "서비스약관",
		"Referral Uid" : "추천코드",
		"Referral Link" : "레퍼럴링크",
		"Rebate Ratio" : "리베이트비율 : "
	},


	// 대시보드
	"Rebate Ratio": "리베이트 비율",
	"Rebate Ratio(%)": "리베이트 비율(%)",
	"Dashboard" : "대쉬보드",
	"Notice" : "공지사항",
	"Rebate": "리베이트",
	"User": "사용자",
	"Wallet": "지갑",
	"User Management": "사용자 관리",
	"Accounts Overview": "고객계정 현황",
	"Configurations": "고객계정 설정",
	"Deposit/Withdrawal": "입출금내역",
	"exchange_spot" : "현물지갑 입금/출금",
	"exchange_futures" : "선물지갑 입금/출금",

	"dashboard" : {
		"Team Daily Report": "팀 일간 리포트",
		"Yesterday (UTC+0)" : "어제통계 (UTC 기준)",
		"(Data is updated at UTC+1)": "(데이터는 UTC+1에 업데이트됩니다.)",
		"New Registration" : "팀 어제 신규가입자",
		"Deposit" : "팀 어제 입금액",
		"Transaction Volume" : "팀 어제 거래액",
		"Transaction Fee" : "팀 어제 수수료",
		"Rebate" : "팀 어제 리베이트",
		"Deposit Chart" : "입금액 추세 차트",
		"Transaction Volume Chart" : "거래액 추세 차트",
		"Transaction Fee Chart" : "수수료 추세 차트",
		"Rebate Chart" : "리베이트 추세 차트",
		"New Registration Chart" : "신규가입자 추세 차트",
	},

	// 공지사항
	"Notice List": "공지사항 목록",
	"List": "목록",
	"No": "번호",
	"Title": "제목",
	"Date": "날짜",
	"Dense": "좁게보기",
	"Notice Details": "공지사항 보기",
	"Go List": "목록으로",

	"rebate" : {
		"detail" : {
			"Rebate Total Summary" : "리베이트 누적현황",
			"Rebate Transactions": "리베이트 입금기록",
			"My Rebate" : "내 리베이트 누적합계",
			"Self Rebate" : "내 셀프리베이트 누적 합계",
			"Client Transaction Fee" : "고객 수수료 누적합계",
			"Client Rebate" : "고객 리베이트 누적합계",
			"Partner Transaction Fee" : "파트너 수수료 누적합계",
			"Partner Rebate" : "파트너 리베이트 누적합계",
			"User Type" : "사용자유형",
			"Transaction Fee": "거래 수수료",
			"Rebate Ratio": "리베이트비율",
			"Rebate(USDT)": "리베이트(USDT)",
			"Time": "발생일시",
		},
		"deposit_record" : {
			"Coin" : "코인",
			"EXPECTED REBATE" : "예상리베이트",
			"ACTUAL REBATE" : "확정리베이트",
			"Time" : "이체일시"
		}
	},
	"customer" : {
		"report" : {
			"Rebate Ratio" : "리베이트비율 (%)",
			"User Type" : "사용자유형",
			"Total Rebate" : "리베이트합계",
			"Total Transaction Fee" : "수수료합계",
			"Total Deposit" : "입금합계",
			"Total Withdrawal" : "출금합계",
			"Node Size" : "팀사이즈",
			"Deposit Users" : "입금사용자수",
			"Email" : "이메일",
			"Phone" : "전화번호",
			"Note" : "비고",
			"Registration" : "가입일시"
		},
		"setting" : {
			"Email" : "이메일",
			"Phone" : "전화번호",
			"Note" : "비고",
			"Rebate Ratio" : "리베이트비율 (%)",
			"Set Partner" : "파트너설정",
			"Edit" : "수정",
			"Actions": "도구",
		}
	},
	"exchange" : {
		"Transaction" : "거래유형",
		"Deposit" : "입금",
		"Withdraw" : "출금",
		"Coin" : "코인",
		"Quantity" : "수량",
		"Time" : "입출금일시",
		"Spot Wallet Transactions" : "현물지갑 입금/출금",
		"Futures Wallet Transactions" : "선물지갑 입금/출금",
		"Exchange" : "입출금내역",
	},

};

export default kr;
